<template>
  <articleHead :info="{ title: imgsTitle }" :isArticle="false" />
  <div v-if="isPass" class="check-box">
    <div class="check-content">
      <div class="check-tip">请输入密码访问</div>
      <div class="input-box">
        <el-input :placeholder="info.ques" v-model="answer">
          <template #append>
            <el-button @click="toImgCheck">提交</el-button>
          </template>
        </el-input>
      </div>
    </div>
  </div>
  <template v-else>
    <div class="img_box">
      <el-skeleton
        :rows="4"
        style="width: 90%; margin: auto"
        :loading="imgloading"
        animated
      >
        <div v-for="(item, index) in imgList" :key="item.id" class="img_cover">
          <el-image
            lazy
            :alt="item.title"
            :title="item.title"
            class="image"
            fit="cover"
            :src="item.src + '?imageMogr2/format/webp'"
            :preview-src-list="urls"
            :initial-index="index"
            ><template #placeholder>
              <div class="image-slot">Loading<span class="dot">...</span></div>
            </template></el-image
          >
        </div>
      </el-skeleton>
    </div>
    <div class="img_text" v-if="text">{{ text }}</div>
  </template>
</template>
<script>
import { useGet, usePost } from "@/hooks/index";
import { htmlEncode } from "@/hooks/inputcheck";
import {
  urlForGetImgs,
  urlForImageIsPass,
  urlForImagePassCheck,
} from "@/api/url";
import articleHead from "@/components/articleHead/articleHead.vue";
const get = useGet();
const post = usePost();
export default {
  components: {
    articleHead,
  },
  data() {
    return {
      id: 0,
      imgloading: true,
      imgList: [],
      imgsTitle: "",
      text: "",
      urls: [],
      isPass: false,
      info: {},
      answer: "",
    };
  },
  mounted() {
    let id = this.$route.query && this.$route.query.id;
    this.id = id;
    let title = this.$route.query && this.$route.query.title;
    this.imgsTitle = title == "此内容受密码保护" ? title : title;
    document.title = title + '-合集'+id
    // this.getImgs(id);
    this.isPassWord(id);
  },
  methods: {
    isPassWord(id) {
      get({
        url: urlForImageIsPass + "?id=" + id,
      })
        .then((res) => {
          let info = res.data[0];
          this.info = info;
          if (info.ispassword) {
            this.isPass = true;
          } else {
            this.getImgs(id, "无");
          }
          this.imgloading = false;
        })
        .catch((err) => {
          this.$message.error("网络错误");
          this.imgloading = false;
        });
    },
    getImgs(id, anwser) {
      get({
        url: urlForGetImgs + "?id=" + id + "&anwser=" + anwser,
      })
        .then((res) => {
          this.imgList = res.data;
          this.imgsTitle = res.info.title;
          this.text = res.info.text;
          this.urls = res.data.map((item) => {
            return item.src;
          });
          //   console.log('this.urls',res.data,this.urls)
          this.imgloading = false;
        })
        .catch((err) => {
          this.imgList = [];
          this.imgloading = false;
        });
    },
    toImgCheck() {
      let anwser = htmlEncode(this.answer.trim());
      if (anwser.length == 0) return;
      let _this = this;

      post({
        url: urlForImagePassCheck,
        data: {
          id: this.id,
          anwser,
        },
      })
        .then((data) => {
          // console.log(data)
          if (data.data[0]) {
            this.isPass = false;
            this.getImgs(this.id, anwser);
          } else {
            _this.$message.warning({
              message: "密码错误",
            });
          }
        })
        .catch((err) => {
          _this.$message.error({
            message: "网络错误",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.check-box {
  width: 100%;
  padding: 20px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  background-color: var(--bgcolor);
  .check-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--main-bg);
    padding: 30px;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 var(--boxshadow);
    .check-tip {
      text-align: center;
      color: var(--imagetext);
    }
    .input-box {
      margin-top: 30px;
      width: 200px;
    }
  }
}
.img_box {
  width: 100%;
  // min-height: 1000px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  // justify-content: space-between;
  .img_cover {
    width: calc(33.3% - 20px);
    height: 132px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    background: var(--main-bg);
    border-radius: 6px;
    box-shadow: 0 8px 16px rgb(0 0 0 / 30%);
    overflow: hidden;
    cursor: pointer;
    .image {
      height: 100%;
      .image-slot {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: var(--el-fill-color-light);
        color: var(--el-text-color-secondary);
        font-size: 14px;
      }
      .dot {
        animation: dot 2s infinite steps(3, start);
        overflow: hidden;
      }
    }
    .img_title {
      width: 100%;
      height: 32px;
      text-align: center;
      line-height: 32px;
      overflow: hidden;
      font-size: 14px;
    }
  }
}
.img_text{
  margin-bottom: 20px;
  padding: 0px 30px 20px;
  color: var(--normaltextcolor);
}
:deep(.el-input__wrapper) {
  background-color: var(--inputbg) !important;
  box-shadow: 0 0 0 1px var(--inputboxshadow) inset !important;
}
:deep(.el-input__inner) {
  color: var(--normaltextcolor);
}
:deep(.el-input-group__append) {
  background-color: var(--inputbg) !important;
  box-shadow: 0 0 0 1px var(--inputboxshadow) inset !important;
}
</style>
